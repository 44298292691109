import {
  Alert,
  Avatar,
  Breadcrumbs,
  Button,
  Card,
  Grid,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import useGlobalContext from '../../Hooks/useGlobalContext';
import { ApiFetch } from '../../Utils/ApiFetch';

import { useTranslation } from 'react-i18next';
import {
  BOXES_EDIT,
  BOXES_NEW,
  COMPANY_ALL,
} from '../../constants/paths';
import PopoverOptions from './components/PopoverOptions';

function BoxesAll() {
  const { t } = useTranslation();
  let history = useHistory();
  const globalContext = useGlobalContext();
  const { selectedCompanyAdmin, calculateActualQuota, userData } = globalContext;
  const [boxes, setBoxes] = useState([]);
  const [route, setRoute] = useState([]);
  const [parentId, setParentId] = useState(null);
  const [parentSubtype, setParentSubtype] = useState(null);
  const [alertInfo, setAlertInfo] = useState({
    show: false,
    alertText: '',
  });
  const [disabledAdd, setDisabledAdd] = useState(true);

  const location = useLocation();

  const loadQuotaModal = async () => {
    const { remainingQuota } = await calculateActualQuota({
      id: selectedCompanyAdmin.id,
      type: 'box'
    });
    if (remainingQuota <= 0) {
      setAlertInfo((current) => ({
        ...current,
        show: true,
        alertText: t('quotaExceeded', { type: t('box') }),
      }));
    }
  };

  useEffect(() => {
    loadQuotaModal();
    if (userData?.userData?.permissions?.companies[selectedCompanyAdmin.id]?.includes('BOX_ADD')) {
      setDisabledAdd(false);
    } else {
      setDisabledAdd(true);
    }
  }, []);

  useEffect(() => {
    loadQuotaModal();
    if (userData?.userData?.permissions?.companies[selectedCompanyAdmin.id]?.includes('BOX_ADD')) {
      setDisabledAdd(false);
    } else {
      setDisabledAdd(true);
    }
  }, [selectedCompanyAdmin.id]);

  useEffect(() => {
    setRoute((location && location.state && location.state.route) || []);

    const parentIdParam =
      (location && location.state && location.state.parentId) || null;
    const parentSubtypeParam =
      (location && location.state && location.state.parentSubtype) || null;

    if (parentIdParam != null) {
      let data = {
        id: parentIdParam,
        subtype: parentSubtypeParam,
      };
      getBoxesByParentId(data, selectedCompanyAdmin.id, false);
    } else {
      bringBoxesData(selectedCompanyAdmin);
    }
  }, [selectedCompanyAdmin]);

  const bringBoxesData = (company) => {
    if (company && company.id !== undefined && company.id !== -1) {
      setRoute([]);
      ApiFetch(
        'boxes/' + company.id + '/companies',
        globalContext,
        true,
        async (res) => {
          if (res.status === 200) {
            let data = await res.json();
            setBoxes(data);
          }
        },
        (error) => {
          console.log('error boxes', error);
        },
        {
          method: 'GET',
        }
      );
    }
  };

  const getBoxesByParentId = (parent, companyId, updateBreadcrum) => {
    setParentId(parent.id);
    setParentSubtype(parent.subtype);

    ApiFetch(
      'boxes/' + parent.id + '/parentboxes?companyId=' + companyId,
      globalContext,
      true,
      async (res) => {
        if (res.status === 200) {
          let data = await res.json();
          setBoxes(data);
          if (updateBreadcrum) setRoute((old) => [...old, parent]);
        }
      },
      (error) => {
        console.log('error boxes', error);
      },
      {
        method: 'GET',
      }
    );
  };

  const onClickAddBox = () => {
    let args = {
      companyId: selectedCompanyAdmin.id,
      parentId: parentId,
      route: route,
      parentSubtype: parentSubtype,
      subtype: null,
      fromList: true,
    };

    history.push({
      pathname: BOXES_NEW,
      state: args,
    });
  };

  const onClickEditBox = (boxId, _subtype) => {
    let args = {
      companyId: selectedCompanyAdmin.id,
      boxId: boxId,
      parentSubtype: parentSubtype,
      subtype: _subtype,
      parentId: parentId,
      route: route,
      fromList: true,
    };

    history.push({
      pathname: BOXES_EDIT,
      state: args,
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} className="mb-2">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center">
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              color="inherit"
              onClick={() => {
                history.push({
                  pathname: COMPANY_ALL,
                  state: {},
                });
              }}>
              {t('stepByStep.facility.companyTitle')}
            </Link>
            <Link
              underline="hover"
              color="inherit">
              {t('stepByStep.facility.facilityTitle')}
            </Link>
            {route.map((data, index) => (
              <Link
                key={index}
                underline="hover"
                color="inherit"
              >
                {data.value}
              </Link>
            ))}
          </Breadcrumbs>
          {alertInfo.show && (
            <span className="d-flex flex-row align-center">
              <Alert severity="error">
                <div
                  dangerouslySetInnerHTML={{
                    __html: alertInfo.alertText,
                  }}></div>
              </Alert>
            </span>
          )}
          <Button
            className="ml-2"
            variant="contained"
            disabled={alertInfo.show || disabledAdd}
            onClick={() => {
              onClickAddBox();
            }}>
            {parentId != null && t('stepByStep.button.facility.newSub')}
            {parentId == null && t('stepByStep.button.facility.new')}
          </Button>
        </Stack>
      </Grid>

      {boxes.length === 0 && (
        <Grid item xs={4}>
          {parentId != null && <div>{t('stepByStep.facility.noSubData')}</div>}
          {parentId == null && <div>{t('stepByStep.facility.noData')}</div>}
        </Grid>
      )}
      {boxes.map((data, index) => (
        <Grid item xs={4} key={index}>
          <Card className="pt-2 pl-2 pb-2">
            <div className="d-flex flex-row justify-between pr-2">
              <div className="d-flex flex-row align-start">
                <Avatar aria-label="recipe" className="mr-2 mt-1">
                  {data.value[0].toUpperCase()}
                </Avatar>
                <span>
                  <Typography
                    variant="subtitle2"
                    component="p"
                    color="primary"
                    className="mb-2"
                  >
                    <Link
                      component="button"
                      style={{ textDecoration: 'none', fontWeight: 'bold' }}
                      onClick={() => {
                        getBoxesByParentId(data, selectedCompanyAdmin.id, true);
                      }}>
                      {data.value.length > 30
                        ? `${data.value.substring(0, 30)}...`
                        : data.value}
                    </Link>
                    <br />
                    alias: {data?.mds?.alias?.value}
                  </Typography>
                  <Typography
                    variant="caption"
                    component="p"
                    color="text.light">
                    {t('stepByStep.facility.cardTitle')}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    component="p"
                    className="mt-0 mb-2">
                    {data.companyname}
                  </Typography>
                </span>
              </div>
              <div>
                <PopoverOptions onClickEditBox={onClickEditBox} data={data} />
              </div>
            </div>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}

export default BoxesAll;
