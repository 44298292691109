import Timeline from '@mui/lab/Timeline';
import { TimeLineCard } from './TimeLineCard';

export const TimeLine = ({ information, tt }) => {
  return (
    <Timeline className='pr-0 pl-0'>
      {information.map((tl, index) => (
        <TimeLineCard key={index} tt={tt} information={tl} />
      ))}
    </Timeline>
  );
};
