const { validateField, validateGrid } = require("@origino-io/fieldvalidations");

const fieldsValidations = (value, validations, options = {}) => {
  if(options.fieldType === 'checkbox' || options.fieldType === 'multiselectbox' || options.fieldType === 'groupedmultiselectbox')
    return validationMultiSelectBox(value, validations);

  if(options.fieldType === 'text' && options.fieldName === 'company_email')
    return validationEmail(value)

  if(options.fieldType === 'file'){
    return (validations.mandatory?.value && !value) ? [validations.mandatory?.message] : [];
  }

  if(options.fieldType === 'autocomplete'){
    return (validations.mandatory?.value && !value) ? [validations.mandatory?.message] : [];
  }
  return validateField(value, validations, options);
}

const validationMultiSelectBox = (value, validations) => {
  return (validations.mandatory && !value) ? [validations.mandatory?.message] : []
}

const validationEmail = (value) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return (value && !regex.test(value)) ? ['Complete correctamente el Email'] : []
}

const validateGridWithFiles = async (assets, fields, errors, fieldsFiltered, options) => {
  let { errorCount, listErrorsId, resultErrors, resultUniques } =
  await validateGrid(assets, fieldsFiltered, options);

  for(let row = 0; row < assets.length; row++) {
    for(const field of fields) {
      if(field.type === 'file'){
        const hasErrors = fieldsValidations(
          assets[row][field.name],
          field.validations,
          {
            fieldType: field.type
          }
        )
          if(hasErrors.length !== 0) {
            const error = {row: row, column: field.name, errors: hasErrors}
            if(!errors.some(err =>
              err.row === error.row
              && err.column === error.column
            )){
              listErrorsId.push(assets[row].id);
              resultErrors.push(error);
              errorCount += 1;
            }
          }
      }
    }
  }
  return {listErrorsId, resultErrors, errorCount, resultUniques};
}

module.exports = {
  fieldsValidations,
  validateGridWithFiles,
};