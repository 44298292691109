import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useTranslation } from 'react-i18next';
import { QrTemplate } from '../components';
import { getQrProductoTrupo } from '../../../services/qr';

export const QrProduktoTrupo = () => {
  const { id } = useParams();
  const [attributes, setAttributes] = useState()
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState(false)

  useEffect(() => {
    getQrProductoTrupo(id)
      .then(res => {
        if (res.status === 206) {
          setErrorMessage(res.data)
        } else setAttributes(res.data)
      })
      .catch(err => {
        console.log('Error getQrIdentecoTrupo', err)
        setErrorMessage(true)
      })

  }, [id]);

  return (
    <QrTemplate
      attributes={attributes}
      editMode={false}
      errorMessage={errorMessage ? errorMessage : ''}
    />
  )
}
