import React, { useEffect, useState } from 'react';
import { Grid, Stack, Button, Box } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import useGlobalContext from '../../Hooks/useGlobalContext';
import { useHistory } from 'react-router-dom';
import { EVENTS_MASSIVE } from '../../constants/paths';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { getBoxActivityEvent } from '../../services/boxes';
import { useTranslation } from 'react-i18next';

const cols = [
  { field: 'id', headerName: 'Id del evento' },
  { field: 'event_type', headerName: 'Tipo de Evento' },
  { field: 'event_assets_count', headerName: 'Assets involucrados' },
  { field: 'timsta', headerName: 'Fecha', type: 'dateTime' },
  { field: 'username', headerName: 'Usuario' },
]

function EventsMain() {
  const history = useHistory();
  const { t } = useTranslation();
  const { userData, selectedBox, selectedAsset } =
    useGlobalContext();
  const [data, setData] = useState()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    getBoxActivityEvent(selectedBox.id)
      .then(res => setData(res.data))
      .catch(err => console.log(err))
      .finally(() => setIsLoading(false))
  }, [selectedBox.id])

  const redirectToMassiveEvents = () =>  {
      history.push({
      pathname: EVENTS_MASSIVE, 
      state: { source: 'events' }, 
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Button
            sx={{ ml: 2 }}
            variant="contained"
            disabled={!(userData?.userData?.permissions?.boxes[selectedBox.id + "-" + selectedAsset.id]?.includes("TAG_ADD") === true)}
            onClick={redirectToMassiveEvents}
            startIcon={<FontAwesomeIcon icon={faPlus} />}>
            {t("breadcrumb.events.button.new")}
          </Button>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ height: 400, width: '100%' }}>
          {data &&
            <DataGrid
              loading={isLoading}
              rows={data}
              columns={cols}
              disableDensitySelector
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
            />
          }
        </Box>
      </Grid>
    </Grid>
  );
}

export default EventsMain;
