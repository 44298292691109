import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Typography, TextField, FormControlLabel, Checkbox, Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/pro-light-svg-icons';
import BaseBasicForm from '../../../Layout/containers/BaseBasicForm';
import { ApiFetch } from '../../../Utils/ApiFetch';
import useGlobalContext from '../../../Hooks/useGlobalContext';
import leftIcon from '../../../Images/stepbystep/lefticons/bienvenido.png';
import { LOGIN } from '../../../constants/paths'
import { useTranslation } from 'react-i18next';

function SignUpUser() {
  const { t } = useTranslation();
  let history = useHistory();
  const globalContext = useGlobalContext();

  const [checkTerms, setCheckTerms] = useState(false);
  const [showErrorUser, setShowErrorUser] = useState(null);
  const [msgErrorUser, setMsgErrorUser] = useState('');
  const [equalPass, setEqualPass] = useState(false);
  const [showErrorPass, setShowErrorPass] = useState(null);
  const [msgErrorPass, setMsgErrorPass] = useState('');
  const [showErrorNames, setShowErrorNames] = useState(null);
  const [msgErrorNames, setMsgErrorNames] = useState('');
  const [showErrorAPI, setShowErrorAPI] = useState(null);
  const [msgErrorAPI, setMsgErrorAPI] = useState('');
  const [email, setEmail] = useState('');
  const [pass1, setPass1] = useState('');
  const [pass2, setPass2] = useState('');
  const [firstName, setFirstName] = useState('');
  const [disabledSubmit, setDisabledSubmit] = useState(false);
  const [lastName, setLastName] = useState('');
  const [country] = useState('');

  const handleInputOnBlur = (input) => {
    switch (input) {
      case 'email':
        if (!email) {
          setShowErrorUser(true)
          setMsgErrorUser('emailRequired')
          return
        } else {
          setShowErrorUser(false)
          const emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
          if (!emailRegex.test(email)) {
            setShowErrorUser(true)
            setMsgErrorUser('wrongEmailFormat')
            return
          }
        }
        break;

      case 'pass1':
        if (pass1.length < 6 || pass1.length > 50) {
          setShowErrorPass(true)
          setMsgErrorPass('invalidLengthPass')
          return
        } else setShowErrorPass(false)
        if (!equalPass && pass2) {
          setShowErrorPass(true)
          setMsgErrorPass('differentPasswords')
          return
        } else setShowErrorPass(false)
        break;

      case 'pass2':
        if (pass2.length < 6 || pass2.length > 50) {
          setShowErrorPass(true)
          setMsgErrorPass('invalidLengthPass')
          return
        } else setShowErrorPass(false)
        if (!equalPass) {
          setShowErrorPass(true)
          setMsgErrorPass('differentPasswords')
          return
        } else setShowErrorPass(false)
        break;

      case 'firstName':
        if (firstName.length <= 1) {
          setShowErrorNames(true)
          setMsgErrorNames('namesLength')
          return
        } else setShowErrorNames(false)
        break;

      case 'lastName':
        if (lastName.length <= 1) {
          setShowErrorNames(true)
          setMsgErrorNames('namesLength')
          return
        } else setShowErrorNames(false)
        break;

      default: return
    }
  }

  const handleOnClick = (event) => {

    if (checkTerms) {
      let args = {
        email: email,
        password: pass1,
        firstname: firstName,
        lastname: lastName,
        l1: country,
        userData: {
          box_context: {
            model: 5,
            parentId: 0,
            parentType: 'company',
            objectTypeSubType: 2,
            parentTypeSubType: 1,
          },
          asset_context: {
            model: 5,
            parentId: 100,
            parentType: 'box',
            objectTypeSubType: 5,
            parentTypeSubType: 1,
          },
        },
      };

      setDisabledSubmit(true);
      ApiFetch(
        'users/signUp',
        globalContext,
        false,
        async (result) => {
          if (result.status === 200) {
            history.push({
              pathname: '/public/signup/verify',
              state: { email: email },
            });
          }
        },
        async (error) => {
          setShowErrorAPI(true);
          try {
            const res = await error.json();
            setMsgErrorAPI(res.message);
          } catch (error) {
            setMsgErrorAPI("serverLostConnection");
          }
          setDisabledSubmit(false);
        },
        {
          method: 'POST',
          body: JSON.stringify(args),
        }
      );
    }
  };

  const checkPass1 = (value) => {
    setPass1(value)
    if (value === pass2) setEqualPass(true)
    else setEqualPass(false)
  }

  const checkPass2 = (value) => {
    setPass2(value)
    if (value === pass1) setEqualPass(true)
    else setEqualPass(false)
  }

  const fullItemCheck = () => {
    if (showErrorUser === false && showErrorPass === false && showErrorNames === false) return true
    else return false
  }

  return (
    <BaseBasicForm text={t("signUp.title")} leftIcon={leftIcon} showOriginoImage="true">
      <FontAwesomeIcon icon={faUserPlus} size="2x" color="#3969E8" />
      <Typography variant="subtitle1" component="h4" color="text.secondary" className="mb-4">
        {t("signUp.subtitle")}
      </Typography>
      <TextField
        id="email"
        label={t("signUp.email")}
        className="mt-4"
        onChange={(e) => setEmail(e.target.value)}
        onBlur={() => handleInputOnBlur('email')}
        fullWidth
      />
      {(showErrorUser) &&
        <span style={{ textAlign: "center", color: "red" }}>
          <br />{t("signUp.errorMsg." + msgErrorUser)}<br />
        </span>
      }
      <TextField
        id="password"
        label={t("signUp.password1")}
        type="password"
        onChange={(e) => checkPass1(e.target.value)}
        onBlur={() => handleInputOnBlur('pass1')}
        helperText={t("signUp.passwordHelperText")}
        className="mt-2"
        fullWidth
      />
      <TextField
        id="password_2"
        label={t("signUp.password2")}
        type="password"
        onChange={(e) => checkPass2(e.target.value)}
        onBlur={() => handleInputOnBlur('pass2')}
        helperText={t("signUp.passwordHelperText")}
        className="mt-2"
        fullWidth
      />
      {(showErrorPass) &&
        <span style={{ textAlign: "center", color: "red" }}>
          <br />{t("signUp.errorMsg." + msgErrorPass)}<br />
        </span>
      }
      <TextField
        id="firstname"
        label={t("signUp.firstName")}
        className="mt-4"
        onChange={(e) => setFirstName(e.target.value)}
        onBlur={() => handleInputOnBlur('firstName')}
        fullWidth
      />
      <TextField
        id="lastname"
        label={t("signUp.lastName")}
        className="mt-4"
        onChange={(e) => setLastName(e.target.value)}
        onBlur={() => handleInputOnBlur('lastName')}
        fullWidth
      />
      {(showErrorNames) &&
        <span style={{ textAlign: "center", color: "red" }}>
          <br />{t("signUp.errorMsg." + msgErrorNames)}<br />
        </span>
      }
      <div className='flex-row justify-content-center'>
        <FormControlLabel
          control={<Checkbox checked={checkTerms} onChange={() => setCheckTerms(!checkTerms)} />}
          label={t("signUp.termsAndConditions.0")}
          className="flex my-4 mx-0 align-middle"
        />
        <a className="flex my-4 mx-1 align-middle text-decoration-none"
          href="/TermsAndConditions_Origino_ES.html" target="_blank">
          {t("signUp.termsAndConditions.1")}
        </a>
      </div>
      {(showErrorAPI) &&
        <span style={{ textAlign: "center", color: "red" }}>
          {t("signUp.errorMsg." + msgErrorAPI)}<br />
        </span>
      }
      {(msgErrorAPI === 'userExist') &&
        <span style={{ textAlign: "center" }}>
          <a className="text-decoration-none" href={LOGIN}>{t("signUp.errorMsg.loginHere")}</a>
        </span>
      }
      {checkTerms && fullItemCheck() && (
        <Button variant="contained" className="mt-4" disabled={disabledSubmit} onClick={handleOnClick}>
          {t("signUp.createAccount")}
        </Button>
      )}
      {(!checkTerms || !fullItemCheck()) && (
        <Button variant="contained" className="mt-4" disabled>
          {t("signUp.createAccount")}
        </Button>
      )}
    </BaseBasicForm>
  );
}

export default SignUpUser;
