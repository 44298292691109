import React, { useEffect, useState} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ApiFetch } from '../../Utils/ApiFetch';
import useGlobalContext from '../../Hooks/useGlobalContext';
import {
  Alert,
  Grid,
  Stack,
  Button,
  Breadcrumbs,
  Link,
  Typography,
  Card,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent
} from '@mui/material';

import { USER_DELEGATIONS } from '../../constants/paths';
import UserDelegationsCompanyStepper from './UserDelegationsCompanyStepper';
import { useTranslation } from 'react-i18next'

import ConfirmDialog from '../../Components/Feature/ConfirmDialog';

function UserDelegationsCompanyAll() {
  const { t } = useTranslation()
  let history = useHistory();
  const globalContext = useGlobalContext();
  const { calculateActualQuota, selectedCompany } = globalContext;
  const [users, setUsers] = useState([]);;
  const [dialog, setDialog] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [dataDelegation, setDataDelegation] = useState({});

  const location = useLocation();

  const [alertInfo, setAlertInfo] = useState({
    show: false,
    alertText: '',
  });

  useEffect(() => {
    getAllUsers();
    loadQuotaModal();
  }, []);

  useEffect(() => {
    loadQuotaModal();
  }, [users])

  const getAllUsers = () => {
    ApiFetch(
      'users/delegations/company/' + location.state?.companyDelegate.id,
      globalContext,
      true,
      async (res) => {
        if (res.status === 200) {
          let data = await res.json();
          setUsers(data);
        }
      },
      (error) => {
        console.log('error users/delegations/company/', error);
      },
      {
        method: 'GET',
      }
    );
  };

  const deleteCompanyAsign = (data) => {
    const payload = {
      user: data.id,
      rol: data.id_template,
      company: location.state?.companyDelegate.id
    };

    ApiFetch(
      'users/delegations/deleteCompanyAsign',
      globalContext,
      true,
      async (res) => {
        if (res.status === 200) {
          const newUser = users.filter(u => u.id !== data.id);
          setUsers(newUser);
        }
        else{
          console.log("Delete error: ",res);
        }
      },
      (error) => {
        console.log('error users/delegations/deleteCompanyAsign', error);
      },
      {
        method: 'POST',
        body: JSON.stringify(payload)
      }
    );
  };


  const onClickDeleteRol = (data) => {
    deleteCompanyAsign(data);
  }

  const onClick = (company) => {
    //saveUser({ id: company.id, name: company.value });

    //history.push({
    //  pathname: USER_DELEGATIONS,
    //});
  };

  const handleOnConfirm = () => {
    onClickDeleteRol(dataDelegation);
  }

  const handleOpenConfirm = (data) => {
    setDataDelegation(data);
    setConfirmOpen(true);
  }

  const loadQuotaModal = async () => {
    const { remainingQuota, companiesOwnerLength } = await calculateActualQuota({
      id: selectedCompany.id,
      type: 'companyDelegations'
    });
    if (remainingQuota <= 0 && companiesOwnerLength > 0) {
      setAlertInfo((current) => ({
        ...current,
        show: true,
        alertText: t('quotaExceeded', { type: t('companyDelegations') }),
      }));
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} className="mb-2">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit"

                onClick={
                  () => {
                    //bringBoxesData(selectedCompany) // todo sandbox
                    history.push({
                      pathname: USER_DELEGATIONS,
                      state: {},
                    });
                  }
                }

              >
                {t("breadcrumb.userDelegations.current.title")}
              </Link>
              <Link underline="hover" color="inherit"
                onClick={
                  () => {
                    //bringBoxesData(selectedCompany) // todo sandbox
                    history.push({
                      pathname: USER_DELEGATIONS,
                      state: {},
                    });
                  }
                }
              >
                {t("breadcrumb.userDelegations.current.users")}
              </Link>
              <Link underline="hover" color="inherit">
                {location?.state?.userDelegate?.email}
              </Link>
            </Breadcrumbs>
            <Button
              className="ml-2"
              variant="contained"
              disabled={alertInfo.show}
              onClick={() => {
                setDialog(true)
              }}>
              {t("stepByStep.button.user.assignRoleCompany")}
            </Button>
          </Stack>
        </Grid>

        {alertInfo.show && (
          <Grid item xs={12} className="mb-2">
            <span className="d-flex flex-row align-center">
              <Alert severity="error">
                <div dangerouslySetInnerHTML={{ __html: alertInfo.alertText }}></div>
              </Alert>
            </span>
          </Grid>
        )}

        {users.length === 0 && (
          <Grid item xs={4}>
            <div>{t("stepByStep.msg.noUsers")}</div>
          </Grid>
        )}
        {users.map((data, index) => (
          <Grid item xs={4} key={index}>
            <Card className="pt-2 pl-2 pb-2">
              <div className="d-flex flex-row justify-between pr-2">
                <div className="d-flex flex-row align-start">
                  <Avatar aria-label="recipe" className="mr-2 mt-1">
                    {data.email[0].toUpperCase()}
                  </Avatar>
                  <span>
                    <Typography variant="subtitle2" component="p" color="primary" className="mb-2">
                      <Link style={{ textDecoration: "none", fontWeight: "bold",  cursor: "alias" }} component="button" onClick={() => onClick(data)}>
                        {data.email}
                      </Link>
                    </Typography>
                    <Typography variant="caption" component="p" color="text.light">
                      {data.company_name}
                    </Typography>
                    <Typography variant="subtitle2" component="p" className="mt-0 mb-2">
                      {data.rol && data.rol.split('|').length === 1 ? data.rol : ''}
                      {data.rol && data.rol.split('|').length === 2 ? (
                        <p>
                          {data.rol.split('|')[0]}
                          <br />
                          <small>{data.rol.split('|')[1]}</small>
                        </p>
                      ) : (
                        ''
                      )}
                    </Typography>
                    <Typography variant="caption" component="p" color="text.light">
                        {
                          (data?.delegated_by_user_name)?
                            <p>
                              {t("stepByStep.msg.delegatedBy")} {data.delegated_by_user_name} ({data.delegated_by_user_email})
                            </p>
                          : ''
                        }
                    </Typography>
                  </span>
                </div>
              </div>
              <div>
                <Button variant="contained" disabled={data.isExpertUser} onClick={()=>handleOpenConfirm(data)}>{t("component.popoverOption.delete")}</Button>
              </div>
            </Card>
          </Grid>
        ))}
      </Grid>
      <>
        <Dialog maxWidth="xl" open={dialog} onClose={() => { }}>
          <DialogTitle>{t("component.roles.title")}</DialogTitle>
          <DialogContent style={{ minHeight: "450px", minWidth: "800px", maxWidth: "800px" }}>
            <UserDelegationsCompanyStepper
              onCancel={() => {
                setDialog(false);
              }}
              handleAsign={() => {
                getAllUsers()
                setDialog(false);
              }} />
          </DialogContent>
        </Dialog>
      </>

      <>
        <ConfirmDialog
          title={t("stepByStep.msg.deleteDelegation")}
          open={confirmOpen}
          setOpen={setConfirmOpen}
          onConfirm={handleOnConfirm}
          onClose={()=>{}}
        >
          {t("stepByStep.msg.deleteDelegationConfirmMsg")}
        </ConfirmDialog>
      </>

    </>
  );
}

export default UserDelegationsCompanyAll;
