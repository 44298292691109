import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { Typography, Button } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faExclamationTriangle } from "@fortawesome/pro-light-svg-icons";

import BaseBasicForm from "../../../Layout/containers/BaseBasicForm";
import { ApiFetch } from "../../../Utils/ApiFetch";
import useGlobalContext from "../../../Hooks/useGlobalContext";
import leftIcon from '../../../Images/stepbystep/lefticons/bienvenido.png';
import { useTranslation } from 'react-i18next';

function SignInEmailVerified() {
  const { t } = useTranslation();
  let history = useHistory();
  const { hashParam } = useParams();

  const globalContext = useGlobalContext();
  const [hashOk, setHashOk] = useState(false);
  const [waiting, setWaiting] = useState(true);

  useEffect(() => {
    checkHashIsOk(hashParam);
  }, [hashParam]);

  const checkHashIsOk = async (hash) => {
    ApiFetch(
      "users/validate",
      globalContext,
      false,
      async (result) => {
        const res = await result.json();
        if (result.status === 200 && res.validate) {
          setHashOk(true);
        } else {
          setHashOk(false);
        }
        setWaiting(false);
      },
      (error) => {
        setWaiting(false);
        setHashOk(false);
      },
      {
        method: "POST",
        body: JSON.stringify({ hash: hash }),
      }
    );
  };

  const handleOnClick = () => {
    history.push({
      pathname: "/login",
    });
  };

  return (
    <BaseBasicForm leftIcon={leftIcon} text={t('signUp.title')} showOriginoImage="true">
      {waiting &&
        <Typography variant="h3" component="h4" color="text.primary" align="center" className="mt-2 mb-4">
          {t('signIn.verified.waiting')}
        </Typography>
      }
      {!waiting && !hashOk &&
        <div>
          <div class="d-flex justify-content-center">
            <FontAwesomeIcon icon={faExclamationTriangle} size="3x" color="#FF0000" />
          </div>
          <Typography variant="h3" component="h4" color="text.primary" align="center" className="mt-2 mb-4">
            {t('signIn.verified.hashNoOk')}
          </Typography>
        </div>
      }
      {!waiting && hashOk &&
        <div>
          <Typography variant="h3" component="h4" color="text.primary" align="center" className="mt-2 mb-4">
            {t('signIn.verified.title')}
          </Typography>
          <Typography variant="subtitle1" component="h4" color="text.secondary" align="center" className="mb-4">
            {t('signIn.verified.subtitle')}
            <br />
          </Typography>
          <div class="d-flex justify-content-center">
            <FontAwesomeIcon icon={faCheckCircle} size="3x" color="#3969E8" />
          </div>
          <Typography variant="h3" component="h4" color="text.primary" align="center" className="mt-2 mb-4">
            {t('signIn.verified.hashOk')}
          </Typography>
          <Typography variant="subtitle1" component="h4" color="text.secondary" align="center" className="mb-4"></Typography>
          <div class="d-flex justify-content-center">
            <Button variant="contained" className="mt-4" onClick={handleOnClick}>
              {t('login.button.goLogin')}
            </Button>
          </div>
        </div>
      }
    </BaseBasicForm>
  );
}

export default SignInEmailVerified;
