import { Divider, Typography } from '@mui/material';

export const AttributePlain = ({ data, tt }) => {

 return (
    <>
        <Divider/>
        <Typography color='text.secondary' variant='h3'>
          {tt(`qrTemplate.${data.label}`)}
        </Typography>
        <Typography color='text.grey' variant='subtitle1'>
            {
                data.value
            }
        </Typography>
    </>
 );
};
