import instance from '../Config/axios'
import { QR_URL } from '../constants/requestsUrl';

export const getQrIdentecoTemplate = async (boxId, assetId) => {
  const token = localStorage.getItem('USER_TOKEN')
  const config = {
    headers: {
      Authorization: token,
    },
  };
  const url = `${QR_URL.GET_IDENTECO_TEMPLATE}/${boxId}/${assetId}`;

  return instance.get(url, config);
};

export const updateQrIdentecoTemplate = async (boxId, data) => {
  const token = localStorage.getItem('USER_TOKEN')

  return instance.post(
    QR_URL.SAVE_IDENTECO_TEMPLATE,
    {
      boxId: boxId,
      template: data
    },
    {
      headers: {
        Authorization: token,
      }
    }
  );
};

export const getQrIdenteco = async (assetId) => {
  const url = `${QR_URL.GET_IDENTECO}/${assetId}`;

  return instance.get(url);
};

export const getQrIdentecoTrupoTemplate = (boxId, assetId) => {
  const token = localStorage.getItem('USER_TOKEN')
  const config = {
    headers: {
      Authorization: token,
    },
  };
  const url = `${QR_URL.GET_IDENTECO_TRUPO_TEMPLATE}/${boxId}/${assetId}`;

  return instance.get(url, config);
}

export const getQrIdentecoTrupo = async (assetId) => {
  const url = `${QR_URL.GET_IDENTECO_TRUPO}/${assetId}`;

  return instance.get(url);
};

export const updateQrIdentecoTrupoTemplate = async (boxId, data) => {
  const token = localStorage.getItem('USER_TOKEN')
  const config = {
    headers: {
      Authorization: token,
    },
  };

  return instance.post(QR_URL.SAVE_TEMPLATE_IDENTECO_TRUPO, { boxId: boxId, template: data }, config);
};

export const getQrProductoTrupo = async (assetId) => {
  const url = `${QR_URL.GET_PRODUCTO_TRUPO}/${assetId}`;

  return instance.get(url);
}