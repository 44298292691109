import React from 'react';
import { CkeckStepIcon, InfoStepIcon, RejectedtepIcon, stringAvatar, StyledBadge, StyledBoldTypography, StyledConnector, StyleStepContent, TrasferStepIcon, } from './ArchivoNotificaciones.styles';
import { Avatar, Box, Typography, StepLabel, Step, Stepper, } from '@mui/material';
import NotificationActivo from './NotificationActivo';
import useGlobalContext from '../../Hooks/useGlobalContext';

function ArchivoNotificaciones(props) {
  const globalContext = useGlobalContext();
const { selectedBox } = globalContext;
 //For notification Icon
 const stepIcon = (type) => {
  if (type === 'transfer') {
   return TrasferStepIcon;
  } else if (type === 'info') {
   return InfoStepIcon;
  } else if (type === 'check' || type === 'add' || type === 'update') {
   return CkeckStepIcon;
  } else if (type === 'rejected') {
   return RejectedtepIcon;
  } else if (type === 'newExcel') {
   return CkeckStepIcon;
  }
 };

 //For notification label
 const label = (notificacion) => {
   if (notificacion.type==='add') {
    return (
          <Typography className='flex-row d-flex pt-1'>
          Se ha{notificacion.nro > 1 ? 'n' : ''} creado &nbsp;<b>{notificacion.nro}</b>&nbsp; nuevo{notificacion.nro > 1 ? 's' : ''} activo{notificacion.nro > 1 ? 's' : ''} en el Establecimiento&nbsp;
          <StyledBoldTypography color='primary'>
            {' '}
            {notificacion.boxname}
          </StyledBoldTypography>

          </Typography>
      );
  };

  if (notificacion.type==='update') {
    return (
          <Typography className='flex-row d-flex pt-1'>
          Se ha{notificacion.nro > 1 ? 'n' : ''} modificado &nbsp;<b>{notificacion.nro}</b>&nbsp; activo{notificacion.nro > 1 ? 's' : ''} en el Establecimiento&nbsp;
          <StyledBoldTypography color='primary'>
            {' '}
            {notificacion.boxname}
          </StyledBoldTypography>
          &nbsp;
          {
            notificacion.eventname &&
            <>
            {' mediante un evento de '}
            <StyledBoldTypography color='primary'>
            &nbsp;{notificacion.eventname}
            </StyledBoldTypography>
            </>
          }
          </Typography>
      );
  };

  if (notificacion.type==='transfer') {
    return notificacion.event_holder === selectedBox.id ? (
      <Typography className='flex-row d-flex pt-1'>
        Se ha{notificacion.nro > 1 ? 'n' : ''} transferido &nbsp;<b>{notificacion.nro}</b>&nbsp; activo{notificacion.nro > 1 ? 's' : ''} desde el Establecimiento&nbsp;
        <StyledBoldTypography color='primary'>
          {' '}
          {notificacion.boxname}
        </StyledBoldTypography>
        &nbsp;{'al Establecimiento'}&nbsp;
        <StyledBoldTypography color='primary'>
          {' '}
          {notificacion.boxtarget}
        </StyledBoldTypography>
      </Typography>
    ) : (
      <Typography className='flex-row d-flex pt-1'>
        Se ha{notificacion.nro > 1 ? 'n' : ''} recibido &nbsp;<b>{notificacion.nro}</b>&nbsp; activo{notificacion.nro > 1 ? 's' : ''} desde el Establecimiento&nbsp;
        <StyledBoldTypography color='primary'>
          {' '}
          {notificacion.boxname}
        </StyledBoldTypography>
      </Typography>
    )
  };
}

 return (
  <Box>
   {(props.activities?.length===0 || props.activities==null) &&
      <><b>Aun no se han realizado actividades en este establecimiento.</b></>
    }
   <Stepper
    nonLinear
    orientation='vertical'
    connector={<StyledConnector />}
    activeStep={1}
   >

    <StyleStepContent />

    {props.activities.map((notificacion) => (
     <Step>
      <StepLabel
       StepIconComponent={stepIcon(notificacion.type)}
       optional={
        <Box className='pl-1'>
         {true && (
          <Typography
           className='d-flex flex-row mt-1'
           variant='body1'
           component='p'
           color='text.primary'
          >
           {notificacion.username &&
           <>
           <Avatar
            {...stringAvatar(notificacion.username)}
            className='mr-1'
            color='green'
           />
           {' '}
           {notificacion.username} &middot;
           </>
            }
          </Typography>
         )}
         <Typography variant='body1' component='p' color='text.primary'>
          {notificacion.timsta}
         </Typography>
        </Box>
       }
      >
       <Box className='d-flex pt-3 pl-1'>
        {label(notificacion)}
        {notificacion.new === true ? <StyledBadge badgeContent={1} /> : null}
       </Box>
      </StepLabel>
      <StyleStepContent>
       {notificacion.type === 'newTransfer' ? (
        <NotificationActivo
         activo={notificacion.transferencia.activo}
         box={notificacion.transferencia.box}
         partido={notificacion.transferencia.partido}
         email={notificacion.transferencia.email}
         telefono={notificacion.transferencia.telefono}
        />
       ) : null}
      </StyleStepContent>
     </Step>
    ))}
   </Stepper>
  </Box>
 );
}

export default ArchivoNotificaciones;
