import React, { useState, useEffect, useMemo } from 'react';
import { Grid, Button, Box } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import useGlobalContext from '../../Hooks/useGlobalContext';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { TRANSFERS_MASSIVE } from '../../constants/paths';
import { DataGrid, GridActionsCellItem, GridToolbar } from '@mui/x-data-grid';
import { getTransfersByBox } from '../../services/boxes';
import { SyncAltOutlined } from '@material-ui/icons';

function Transfers() {
  const { t } = useTranslation();
  let history = useHistory();
  const { userData: { userData: { permissions: { boxes: boxesPermissions } } }, selectedBox, selectedAsset } =
    useGlobalContext();
  const [data, setData] = useState()
  const [permissions, setPermissions] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [columns, setColumns] = useState([])

  useEffect(() => {
    setIsLoading(true)
    getTransfersByBox(selectedBox.id)
      .then(res => setData(res.data))
      .catch(err => console.log('err', err))
      .finally(() => setIsLoading(false))
  }, [selectedBox.id])

  const rows = useMemo(() =>{
    if(!data) return [];

    return data.rows.map(row => ({
        ...row,
        status: t(`header.transfers.${row.status}`)
    }));
  },[data, t])

  useEffect(() => {
    if(!data) return [];

    const columns = data.cols.map(col => {
      switch (col.type) {
        case 'algo_tx_link':
          return {
            field: col.field,
            type: 'actions',
            width: col.width,
            headerName: col.headerName,
            getActions: (params) => [
              <GridActionsCellItem
                label='QrActions'
                icon={<SyncAltOutlined />}
                disabled={!params.row.algo_tx_link}
                onClick={(e) => window.open( params.row.algo_tx_link, '_blank')}
              />,
            ]
          }

        default:
          return col
      }
    })
    setColumns (columns)
  }, [data])

  useEffect(() => {
    setPermissions(boxesPermissions[selectedBox.id + "-" + selectedAsset.id])
  }, [selectedBox.id, selectedAsset.id, boxesPermissions])

  const goToNewTransfer = () => history.push(TRANSFERS_MASSIVE)

  return (
    <Grid container spacing={2} sx={{ display: 'flex', flexDirection: 'column', rowGap: '10px' }}>
      <Button
        variant="contained"
        sx={{ maxWidth: '250px' }}
        disabled={!permissions.includes("ASSET_TRANSFER")}
        onClick={goToNewTransfer}
        startIcon={<FontAwesomeIcon icon={faPlus} />}>
        {t('breadcrumb.transfers.button')}
      </Button>

      <Box sx={{ height: 400, width: '100%' }}>
        {data &&
          <DataGrid
            loading={isLoading}
            rows={rows}
            columns={columns}
            disableDensitySelector
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
          />
        }
      </Box>
    </Grid >
  );
}

export default Transfers;

