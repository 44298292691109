import React, { useEffect, useState } from 'react'
import { QrTemplate } from '../components/QrTemplate'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { getQrIdentecoTrupo } from '../../../services/qr';
import { useTranslation } from 'react-i18next';

export const QrIdentecoTrupo = () => {
  const { id } = useParams();
  const [attributes, setAttributes] = useState()
  const [errorMessage, setErrorMessage] = useState(false)
  const { t } = useTranslation();

  useEffect(() => {
    getQrIdentecoTrupo(id)
      .then(res => {
        setAttributes(res.data)
      })
      .catch(err => {
        console.log('Error getQrIdentecoTrupo', err)
        setErrorMessage(true)
      })
  }, [id]);

  return (
    <QrTemplate
      attributes={attributes}
      editMode={false}
      errorMessage={errorMessage ? t('template.error') : ''}
    />
  )
}
