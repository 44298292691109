import instance from '../Config/axios'

export const getTopBarItems = async () => {
  const token = localStorage.getItem('USER_TOKEN')
  const config = {
    headers: {
      Authorization: token,
    },
  };
  const url = `/topBar`;

  return instance.get(url, config);
};

export const getMenuItems = async (modelId) => {
  const token = localStorage.getItem('USER_TOKEN')
  const config = {
    headers: {
      Authorization: token,
    },
  };
  const url = `/topBar/menuItems/${modelId}`;

  return instance.get(url, config);
};

export const getCompaniesOwner = async () => {
  const token = localStorage.getItem('USER_TOKEN')
  const config = {
    headers: {
      Authorization: token,
    },
  };
  const url = `/topBar/owner/companies/all`;

  return instance.get(url, config);
};
