import React, { useEffect, useState, useRef } from 'react';

import {
  Grid,
  Stepper,
  Step,
  StepButton,
  Divider,
  Button,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';

import RenderField from '../../Components/Generic/RenderField';

import { Controller, useForm } from 'react-hook-form';
import { useReactToPrint } from 'react-to-print';

import { WarningAmber } from '@mui/icons-material';
import useGlobalContext from '../../Hooks/useGlobalContext';
import { Label, Pie, PieChart } from 'recharts';
import { CSVLink } from 'react-csv';
import { Redirect, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { ACTIVE_MODEL } from '../../constants';
import { calculateReportPortfolio, getReportPortfolio, getTypeReports } from '../../services/reports';

function Reports(props) {
  const steps = ['Tipo reporte', 'Filtros', 'Dashboard y descargas'];
  const [activeStep, setActiveStep] = useState(0);
  const [completed] = useState({});
  const { handleSubmit, control, formState: { errors }, getValues, watch, resetField } = useForm()
  const watchAllFields = watch()
  const { selectedAsset: { id: assetId } } = useGlobalContext();
  const history = useHistory()

  const [fields, setFields] = useState([])

  const [report, setReport] = useState([])
  const [typeReports, setTypeReports] = useState([]);
  const [reportSelectedActive, setReportSelectedActive] = useState({});

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    if (activeStep === 1) {
      getReportPortfolio(assetId, reportSelectedActive)
        .then(res => setFields(res.data.fieldsInputs))
        .catch(err => console.error('error getReportPortfolio', err))
    }
    if (activeStep === 2) {
      const { reportId, ...dirtyFields } = getValues()
      let fieldsInputs = []
      for (const key in dirtyFields) {
        fieldsInputs.push({ name: key, value: dirtyFields[key] })
      }
      calculateReportPortfolio(reportId, assetId, fieldsInputs)
        .then(res => {
          setReport(res.data)})
        .catch(err => console.error('error calculateReportPortfolio', err))
    }
  }, [activeStep, assetId, getValues, reportSelectedActive])

  useEffect(() => {
    getTypeReports(assetId)
      .then(res => setTypeReports(res.data))
      .catch(err => console.error('Error getTypeReports', err))
  }, [assetId])

  useEffect(() => {
    const subscription = watch((_value, { name }) => {
      if (name === 'group_by_entities')
        resetField('entities_selected', { defaultValue: '' })
    })
    return () => subscription.unsubscribe()
  }, [resetField, watch])


  const filterByFieldDependency = (field) => {
    const dependentField = watchAllFields[field.fieldsDependency]
    const filtered = field.options.filter(option => option[field.keyDependency] === dependentField)
    return filtered
  }

  const onSubmit = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const getTitle = () => {
    const entitySelected = fields.find(field => field.name === 'entities_selected')
      .options?.filter(option => getValues('entities_selected')
        ?.includes(option.key))
      .map(item => item.label).join(', ')
    const entityType = fields.find(field => field.name === 'group_by_entities')
      .options?.find(option => getValues('group_by_entities')
        ?.includes(option.key)).label
    return `${entityType}: ${entitySelected}`
  }

  if (assetId !== ACTIVE_MODEL.vacaPn) {
    return <Redirect to="/assets" state={{ from: history.location }} />
  }

  return (
    <Grid container spacing={2} direction={'column'}>
      <Grid item xs={12}>
        <Stepper nonLinear activeStep={activeStep}>
          {steps.map((label, index) => (
            <Step key={label} completed={completed[index]}>
              <StepButton color="inherit" style={{ cursor: 'default' }}>
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
        <Divider variant="middle" className="mt-4" />
      </Grid>
      <Grid container item xs={12} component={'form'} noValidate
        sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' } }} rowSpacing={2}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid item xs={12} sx={{ minHeight: '300px', boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)', borderRadius: 1 }}>
          {activeStep === 0 &&
            // se podria agregar un svg con los graficos tipo preview (opcional)
            <>
              <Controller
                render={({ field: { name, value, onChange } }) => (
                  <TextField
                    name={name}
                    select
                    value={value}
                    onChange={(e) => { onChange(e); setReportSelectedActive(e.target.value) }}
                    label={'Tipo de reporte'}
                    placeholder={'Tipo de reporte'}
                    error={Boolean(errors[name])}
                    helperText={errors[name] ? errors[name].message : ''}
                  >
                    {typeReports?.map((report) => <MenuItem value={report.id} key={report.id}>{report.name}</MenuItem>)}
                  </TextField>)}
                control={control}
                name={'reportId'}
                defaultValue=""
                rules={{ required: { value: true, message: 'Invalid input' } }}
              />
              <Typography>{typeReports[reportSelectedActive - 1]?.description}</Typography>
            </>
          }
          {activeStep === 1 &&
            fields?.map((fi) => (
              <Controller
                key={fi.name}
                render={({ field: { value, onChange, name } }) =>
                  <RenderField
                    type={fi.type}
                    name={name}
                    value={value}
                    onChange={onChange}
                    label={fi.label}
                    placeholder={fi.label}
                    options={fi?.fieldsDependency ? filterByFieldDependency(fi) : fi.options}
                    error={Boolean(errors[fi.name])}
                    helperText={errors[fi.name] ? <><WarningAmber /> {errors[fi.name].message}</> : ''}
                  />
                }
                control={control}
                name={fi.name}
                defaultValue={fi.defaultValue ?? ''}
                rules={{
                  required: { value: fi?.validations?.mandatory?.value, message: fi?.validations?.mandatory?.message },
                  min: { value: fi?.validations?.min?.value, message: 'Number to low' },
                  max: { value: fi?.validations?.max?.value, message: 'Exeeding number' }
                }}
              />))
          }
          {activeStep === 2 && (
            <div ref={componentRef}>
              <Typography fontSize={24} textAlign={'center'}>{getTitle()}</Typography>
              <br />
              <div style={{ display: 'flex', textAlign: 'center', justifyContent: 'space-around', alignItems: 'center', flexWrap: 'wrap' }}>
                {report.length > 0 && report.map((rep) => (
                  <div key={rep[0].name} style={{ display: 'flex', flexDirection:'column', alignItems:'center'}}>
                    <Typography> {rep[0].name}</Typography>
                    <PieChart width={250} height={200}>
                      <Pie
                        data={rep[0].value !== 0 ? rep : [{value: 1}]}
                        cx="50%"
                        cy="50%"
                        startAngle={-135}
                        endAngle={-405}
                        innerRadius="60%"
                        outerRadius="80%"
                        stroke="none"
                        fill='#8884d8'
                      >
                        <Label value={rep[0].value !== 0 ? rep[0].value : 0} position="center" />
                      </Pie>
                    </PieChart>
                  </div>
                ))}
              </div>
            </div>
          )}
        </Grid>

        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <Button
            color="inherit"
            disabled={activeStep === 0}
            onClick={handleBack}
          >
            Back
          </Button>
          {activeStep !== steps.length - 1
            ? (
              <Button variant="contained" type='submit'>Continuar</Button>
            )
            :
            <>
            { report.length > 0 &&
              <>
                <CSVLink data={report.map(rep => ({ atributo: rep[0].name, valor: rep[0].value }))}
                filename={'reporte.csv'} style={{ textDecoration: 'none', margin: 10 }} >
                  <Button variant="contained">DESCARGAR EXCEL</Button>
                </CSVLink>

                <Button onClick={handlePrint} variant="contained">DESCARGAR PDF</Button>
              </>
            }
            </>
          }
        </Grid>
      </Grid>
    </Grid >
  );
}

export default Reports;
