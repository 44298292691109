import React, { useEffect, useRef, useState } from 'react';
import SidebarLevel from './SidebarLevel';
import { Grid } from '@mui/material';

function SidebarPopover(props) {
  const [levels, setLevels] = useState([]);
  const [nav, setNav] = useState({});
  const [currentLevel, setCurrentLevel] = useState(0);
  const [close, setClose] = useState(false);

  const selectLevel = (indexLevel, item) => {
    let navTemp = { ...nav };
    navTemp[indexLevel] = item;
    let keys = Object.keys(navTemp);
    for (let i = indexLevel + 1; i < keys.length; i++) {
      delete navTemp[i];
    }
    setNav(navTemp);

    let levelsTemp = [];
    for (let indexLevelTemp in levels) {
      if (indexLevelTemp <= indexLevel) {
        levelsTemp.push(levels[indexLevelTemp]);
      }
    }
    setLevels(levelsTemp);
    let currentLevelTemp = currentLevel;
    currentLevelTemp++;
    setCurrentLevel(currentLevelTemp);
    let rand = Math.random();
    levelsTemp.push({
      selectedCompany: props.selectedCompany,
      selectedChain: props.selectedChain,
      parentId: item.id,
      parentName: item.name,
      rand: rand,
    });

    setLevels(levelsTemp);
  };

  const acceptItem = (item) => {
    props.onAcceptItem({ ...item, nav: nav });
  };

  useEffect(() => {
    if (props.selectedChain.id !== -1 && props.selectedCompany.id !== -1) {
      let levelsTemp = [];
      let rand = Math.random();
      levelsTemp.push({
        selectedCompany: props.selectedCompany,
        selectedChain: props.selectedChain,
        parentId: 0,
        parentName: '',
        rand: rand,
      });
      setLevels(levelsTemp);
    }
  }, [props.selectedChain, props.selectedCompany]);

  useEffect(() => {
    if (props.selectedItem.nav) {
      let navA = Object.keys(props.selectedItem.nav);
      if (navA.length > 0) {
        setNav(props.selectedItem.nav);

        let levelsTemp = [];
        for (let i of navA) {
          let rand = Math.random();
          let parentIdTemp = 0;
          let parentNameTemp = '';
          if (i > 0 && props.selectedItem.nav[i - 1]) {
            parentIdTemp = props.selectedItem.nav[i - 1].id;
            parentNameTemp = props.selectedItem.nav[i - 1].name;
          }
          levelsTemp.push({
            selectedCompany: props.selectedCompany,
            selectedChain: props.selectedChain,
            parentId: parentIdTemp,
            parentName: parentNameTemp,
            rand: rand,
          });
        }
        setLevels(levelsTemp);
      }
    }
  }, [props.selectedItem]);

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => setClose(true));

  useEffect(() => {
    if (close) {
      props.closeSidebar();
      setClose(false);
    }
  }, [close]);

  return (
    <div ref={wrapperRef}>
      <Grid container spacing={3}>
        {levels.map((level, index) => {
          return (
            <SidebarLevel
              selectedItem={props.selectedItem}
              selectedIndex={level.selectedIndex}
              level={level}
              index={index}
              onSelect={selectLevel}
              onAcceptItem={acceptItem}
            />
          );
        })}
      </Grid>
    </div>
  );
}

function useOutsideAlerter(ref, handler) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        handler(event);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}

export default SidebarPopover;
