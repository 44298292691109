import { Divider, Typography } from '@mui/material';

export const AttributeDate = ({ data, tt }) => {

  const formatDate = (valueString) => {
    try {
      let dateSplit = valueString.split('T');
      let dateToYYYY = dateSplit[0].split('-');
      return dateToYYYY[2] + '/' + dateToYYYY[1] + '/' + dateToYYYY[0];
    } catch (e) {
      return "";
    }
  };

 return (
    <>
        <Divider />
        <Typography color='text.secondary' variant='h3'>
          {tt(`qrTemplate.${data.label}`)}
        </Typography>
        <Typography color='text.grey' variant='subtitle1'>
            {formatDate(data.value)}
        </Typography>
    </>
 );
};
