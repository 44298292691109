import React, { useState } from 'react';
import { ApiFetch } from '../../Utils/ApiFetch';
import useGlobalContext from '../../Hooks/useGlobalContext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-light-svg-icons";
import { StyledImageSeparator } from "../../pages/Transfers/TransferBoxTheme";

import {
  Grid,
  Button,
  Typography,
  Card,
  Stepper,
  Step,
  Divider,
  StepButton,
  InputAdornment,
  TextField,
  Alert
} from '@mui/material';

import SidebarTopComponent from '../../Layout/components/Sidebar/SidebarTopComponent'
import SelectImageField from '../../Components/Generic/Fields/SelectImageField';
import { useTranslation } from 'react-i18next';

function UserDelegationsStepper(props) {
  const { t } = useTranslation();
  const globalContext = useGlobalContext();
  const [step, handleStep] = useState(0);
  const [selectedBox, setSelectedBox] = useState(null);
  const [roles, setRoles] = useState([]);
  const [selectedRol, setSelectedRol] = useState(null);
  const [userFound, setUserFound] = useState(null);
  const [email, setEmail] = useState("");
  const [sidebarData, setSidebarData] = useState(null);
  const [showNotFound, setShowNotFound] = useState(false);
  const [assignError, setAssignError] = useState(false);
  const [assignWarning, setAssignWarning] = useState(false);

  const searchBoxEmail = () => {
    setShowNotFound(false);
    setUserFound(null);

    ApiFetch(
      'users/searchFromEmail/' + email,
      globalContext,
      true,
      async (res) => {
        if (res.status === 200) {
          let data = await res.json();
          if (data.length > 0) {
            setUserFound(data[0]);

          } else {
            setShowNotFound(true);
          }
        }
      },
      (error) => { },
      {
        method: 'GET',
      }
    );

  }

  const getAllRoles = (box) => {
    ApiFetch(
      'boxes/roles/' + box.id,
      globalContext,
      true,
      async (res) => {
        if (res.status === 200) {
          let data = await res.json();
          if (data.length > 0) {
            let dataTemp = [];
            for (let item of data) {
              let itemTemp = {
                key: item.id,
                label: item.name
              }
              dataTemp.push(itemTemp);
            }
            setRoles(dataTemp);
          }
        }
      },
      (error) => {
        console.log('error boxes/roles', error);
      },
      {
        method: 'GET',
      }
    );
  };

  const selectBox = async (box) => {
    getAllRoles(box);
    setSelectedBox(box);
  }

  const asignRol = async () => {
    let data = {
      user: email,
      rol: selectedRol,
      box: selectedBox
    }
    ApiFetch(
      'users/delegations/asign',
      globalContext,
      true,
      async (res) => {
        if (res.status === 200) {
          if (props.handleAsign) {
            props.handleAsign();
          }
        }
      },
      (error) => {
        if(error.status === 403){
          setAssignWarning(true);
        } else {
          setAssignError(true);
        }
        console.log('error users/delegations/asign', error);
      },
      {
        method: 'POST',
        body: JSON.stringify(data),
      }
    );
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} className="mt-0">
          <Grid item xs={12} className="mt-2">
            <Stepper nonLinear activeStep={step}>
              <Step key={0}>
                <StepButton color="inherit">
                  {t("component.roles.step.selectUser")}
                </StepButton>
              </Step>
              <Step key={1}>
                <StepButton color="inherit">
                  {t("component.roles.step.selectBox")}
                </StepButton>
              </Step>
              <Step key={2}>
                <StepButton color="inherit">
                  {t("component.roles.step.selectRole")}
                </StepButton>
              </Step>
            </Stepper>
          </Grid>
          <Divider variant="middle" className="mt-4" style={{ marginBottom: '5px' }} />
        </Grid>
        {(step === 0) &&

          <>
            <Grid container
              display="flex"
              className="p-2"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item xs={12} className="mt-3"
                alignItems="center"
                justifyContent="center"
              >
                <Typography variant="subtitle1" component="p">
                  {t("component.roles.user")}
                </Typography>
                <div className="d-flex align-center mt-2">
                  <TextField
                    name="user"
                    size="medium"
                    style={{ width: "100%" }}
                    value={email}
                    onChange={(val) => { setEmail(val.target.value) }}
                    placeholder={t("component.roles.user")}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FontAwesomeIcon icon={faSearch} color="#61B15A" />
                        </InputAdornment>
                      ),
                    }}
                  />&nbsp;
                  <Button onClick={() => {
                    searchBoxEmail();
                  }} variant="contained">
                    {t('stepByStep.button.search')}
                  </Button>
                </div>
              </Grid>
              {(showNotFound) &&
                <div className="d-flex align-center mt-2" style={{ color: "red" }}>
                  {t("component.roles.noUserFound")}
                </div>
              }

              <>
                {userFound !== null &&
                  <Grid item xs={12} className="mt-3">
                    <Grid component={Card} container>
                      <Grid item xs={9} className="p-4">
                        <Grid container>
                          <Grid item xs={4}>
                            <Typography variant="body1" component="p" color="text.primary" className="mb-2">
                              {t("component.roles.firstName")}
                            </Typography>
                            <Typography variant="body1" component="p" color="text.primary" className="mb-2">
                              {t("component.roles.lastName")}
                            </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <Typography variant="body1" component="p" className="mb-2">
                              <b>{userFound?.firstname}</b>
                            </Typography>
                            <Typography variant="body1" component="p" className="mb-2">
                              <b>{userFound?.lastname}</b>
                            </Typography>

                          </Grid>
                        </Grid>
                      </Grid>

                      <StyledImageSeparator item xs={3} className="p-0" >
                      </StyledImageSeparator>
                    </Grid>
                  </Grid>
                }
              </>
              <div
                style={{
                  alignSelf: 'flex-end',
                  position: 'absolute',
                  bottom: 35
                }}
              >
                <Button type="button" onClick={() => {
                  if (props.onCancel) {
                    props.onCancel();
                  }
                }} sx={{ mr: 1 }} variant="outlined">
                  {t('stepByStep.button.cancel')}
                </Button>
                <Button type="button" disabled={userFound === null} onClick={() => {
                  handleStep(1);
                }} sx={{ mr: 1 }} variant="contained">
                  {t('stepByStep.button.next')}
                </Button>
              </div>

            </Grid>
          </>
        }
        {(step === 1) &&
          <Grid container
            display="flex"
            className="p-2"
            alignItems="center"
            justifyContent="center"
          >
            <SidebarTopComponent
              data={sidebarData}
              handleBoxSelected={(data) => {
                selectBox(data.establishment);
                setSidebarData(data);
              }
              } />
            <div
              style={{
                alignSelf: 'flex-end',
                position: 'absolute',
                bottom: 35
              }}
            >
              <Button type="button" onClick={() => {
                if (props.onCancel) {
                  props.onCancel();
                }
              }} sx={{ mr: 1, mt: 3 }} variant="outlined">
                {t('stepByStep.button.cancel')}
              </Button>

              <Button type="button" onClick={() => {
                handleStep(0);
              }} sx={{ mr: 1, mt: 3 }} variant="outlined">
                {t('stepByStep.button.back')}
              </Button>

              <Button
                disabled={
                  selectedBox === null
                    ?
                    true
                    :
                    selectedBox?.id === -1
                      ?
                      true
                      :
                      false
                }
                sx={{ mr: 1, mt: 3 }}
                variant="contained"
                onClick={() => {
                  handleStep(2);
                }}>
                {t('stepByStep.button.next')}
              </Button>
            </div>
          </Grid>
        }
        {(step === 2) &&
          <Grid container
            display="flex"
            alignItems="center"
            justifyContent="center"
            className="p-2"
            gap={2}
          >
            <Grid item xs={12}>
            <SelectImageField
              continued={() => { }}
              fieldValues={roles}
              name={"roles"}
              onChange={(field, value) => {

                setSelectedRol(value)

              }}
              value={selectedRol}
            />
            </Grid>
            {(assignError) &&
              <Grid item xs={12}>
                <Alert severity='error' style={{ color: "red", fontWeight: "normal" }}>
                  Ha ocurrido un error, por favor intente nuevamente
                </Alert>
              </Grid>
              }
              {(assignWarning) &&
              <Grid item xs={12}>
                <Alert severity='warning' style={{ color: "black", fontWeight: "normal" }}>
                  El usuario no es válido
                </Alert>
              </Grid>
              }
            <Grid item xs={12}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Button type="button" onClick={() => {
                if (props.onCancel) {
                  props.onCancel();
                  setAssignError(false);
                  setAssignWarning(false);
                }
              }} sx={{ mr: 1, mt: 3 }} variant="outlined">
                {t('stepByStep.button.cancel')}
              </Button>
              <Button type="button" onClick={() => {
                handleStep(1);
                setAssignError(false);
                setAssignWarning(false);
              }} sx={{ mr: 1, mt: 3 }} variant="outlined">
                {t('stepByStep.button.back')}
              </Button>
              <Button
                disabled={(selectedRol == null)}
                sx={{ mr: 1, mt: 3 }}
                variant="contained"
                onClick={() => {
                  asignRol()
                }}>
                {t('stepByStep.button.assign')}
              </Button>
            </div>
            </Grid>
          </Grid>
        }
      </Grid>
    </>
  )
}

export default UserDelegationsStepper;
