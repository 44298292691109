import React from "react";
import { useLocation } from "react-router-dom";
import { Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/pro-light-svg-icons";
import BaseBasicForm from "../../../Layout/containers/BaseBasicForm";
import leftIcon from '../../../Images/stepbystep/lefticons/bienvenido.png';
import { useTranslation } from 'react-i18next';

function SignUpEmailVerify() {
  const { t } = useTranslation();
  const location = useLocation();
  const email = location?.state?.email;

  return (
    <BaseBasicForm leftIcon={leftIcon} text={t('signUp.title')} showOriginoImage="true">
      <FontAwesomeIcon icon={faCheckCircle} size="3x" color="#3969E8" />
      <Typography variant="h3" component="h4" color="text.primary" align="center" className="mt-2 mb-4">
        {t('signUp.verify.title')}
      </Typography>
      <Typography variant="subtitle1" component="h4" color="text.secondary" align="center" className="mb-4">
        <br />
        {t('signUp.verify.subtitle.0') + email + t('signUp.verify.subtitle.1')}
      </Typography>
    </BaseBasicForm>
  );
}

export default SignUpEmailVerify;
