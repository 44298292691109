import { Box, Container, Divider, TextField, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from "react-router-dom";

import { ThemeProvider } from '@mui/material/styles';
import { StyledEngineProvider } from '@mui/material/styles';
import { TrazabilidadBackground } from '../../../assets/vectors/TrazabilidadBackground';
import qrTheme from '../qrTheme'
import { Checkbox, Button } from '@mui/material';
import { Select, MenuItem, StylesProvider, createGenerateClassName } from '@material-ui/core';
import {
  AttributeDate,
  AttributeMultipleCheckbox,
  AttributeImages,
  AttributeLink,
  AttributePlain,
  AttributeTable,
  AttributeTokens,
  AttributeText,
  Footer,
  MapJourney,
  TimeLine,
} from '../components';
import { Spinner } from "reactstrap"
import YouTube from 'react-youtube';
import { DEFAULT_LANGUAGE, DEFAULT_LANGUAGES } from '../../../constants';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { IMAGES_PATH } from '../../../constants/paths';
import {
  AttributeMadurationChart,
} from './AttributeMadurationChart';


export const QrTemplate = ({
  attributes,
  onSubmit,
  errorMessage,
  editMode
}) => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [selectedLang, setSelectedLang] = useState(DEFAULT_LANGUAGE);
  const [languages] = useState(DEFAULT_LANGUAGES);
  const { handleSubmit, control } = useForm()
  const myForm = useRef(null);
  const generateClassName = createGenerateClassName({
    seed: 'qr-theme',
  });
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const isMobile = width <= 768;

  const isValidData = (value) => {
    let outcome = false
    if (Array.isArray(value)) {
      outcome = (value.length > 0)
    } else {
      outcome = (value !== undefined && value !== null && value !== '')
    }
    return outcome
  }

  const doesTimeLineHaveAnyGoodValues = (values) => {
    for (let value of values) {
      if (value.lat !== null && value.lon !== null) {
        return true
      }
    }
    return false
  }

  const handleChangeLanguage = (event) => {
    setSelectedLang(event.target.value);
    i18n.changeLanguage(event.target.value);
  };

  const renderField = (att, index) => {
    switch (att.type) {
      case 'text':
        return (
          <div>
            {att.data.value && (editMode || att.isEnabled) &&
              <AttributeText tt={t} data={att.data} />
            }
          </div>
        )
      case 'url':
        return (
          <div>
            {isValidData(att.data.value) && (editMode || att.isEnabled) &&
              <AttributeLink tt={t} data={att.data} />
            }
          </div>
        )
      case 'video':
        return (
          <div >
            {att.videoUrl && att.isEnabled &&
              <YouTube opts={{ width: isMobile ? '350px' : '700px', height: isMobile ? '150px' : '400px' }} videoId={att.videoUrl} />
            }
          </div>
        )
      case 'plain':
        return (
          <div>
            {isValidData(att.data.value) && (editMode || att.isEnabled) &&
              <AttributePlain tt={t} data={att.data} />
            }
          </div>
        )
      case 'date':
        return (
          <div>
            {isValidData(att.data.value) && (editMode || att.isEnabled) &&
              <AttributeDate tt={t} data={att.data} />
            }
          </div>
        );
      case 'chart':
        const slaughterDate = attributes.find(
          (a) => a.name === 'slaughterDate'
        );
        const hasValidSlaughterDate =
          slaughterDate &&
          slaughterDate.data &&
          new Date(slaughterDate.data.value).toString() !== 'Invalid Date';

        return (
          <div>
            {isValidData(att.data.value) && (editMode || att.isEnabled) && (
              <>
                {hasValidSlaughterDate && (
                  <AttributeMadurationChart
                    value={att.data}
                    data={att}
                    tt={t}
                  />
                )}
              </>
            )}
          </div>
        );
      case 'images':
        return (
          <div style={{ width: '100%' }}>
            {(isValidData(att.data.value)) && (editMode || att.isEnabled) &&
              <AttributeImages tt={t} data={att.data} />
            }
          </div>
        )
      case 'tokens':
        return (
          <div style={{ width: '100%' }}>
            {isValidData(att.data.value) && (editMode || att.isEnabled) &&
              <AttributeTokens tt={t} data={att.data} />
            }
          </div>
        )
      case 'multipleCheckbox':
        return (
          <div style={{ width: '100%' }}>
            {isValidData(att.data.value) && (editMode || att.isEnabled) &&
              <AttributeMultipleCheckbox tt={t} data={att.data} />
            }
          </div>
        )
      case 'table':
        return (
          <div style={{ width: '100%' }}>
            {isValidData(att.data.value.values) && (editMode || att.isEnabled) &&
              <AttributeTable tt={t} data={att.data} />
            }
          </div>
        )
      case 'productJourney':
        return (
          att.values !== undefined && doesTimeLineHaveAnyGoodValues(att.values) && (editMode || att.isEnabled) &&
          <div style={{ width: '100%' }}>
            <Divider />
            <Typography color='text.secondary' variant='h3' className='pt-4'>
              {t('qrTemplate.Recorrido del producto')}
            </Typography>
            <Box
              sx={{
                width: '100%',
                height: '393.01px',
                borderRadius: '17px',
              }}
              className='mt-2 mb-1'
            >
              <MapJourney information={att.values.slice().reverse()} />
            </Box>
          </div>
        )
      case 'timeLine':
        return (
          att.values !== undefined && att.values.length > 0 && (att.isEnabled) &&
          <div style={{ width: '100%' }}>
            <Typography color='text.secondary' variant='h4' className='pt-4'>
              {t("qrTemplate.Recorrido")}
            </Typography>
            <TimeLine tt={t} information={att.values.slice().reverse()} />
            <Divider />
          </div>
        )
      case 'description':
        return (
          att.values && (att.isEnabled) &&
          <div style={{ width: '100%' }}>
            <Typography color='text.grey' variant='h2' className='pt-3 pb-3'>
              {t('qrTemplate.Descripción')}
            </Typography>
            <Typography color='text.grey' variant='body1' className='pt-2'>
              {att.values[selectedLang]}
            </Typography>
            <br />
          </div>
        )
      default:
        return (
          <>
          </>
        )
    }
  }

  const renderDescriptionEdit = (descripcion) => {
    return (
      <div style={editMode ? { border: '1px dashed #aaa', padding: '1rem', display: 'flex', flexDirection: 'row' } : {}} className='mt-3'>
        <div style={{ display: 'flex', paddingTop: '30px' }}>
          <Controller
            render={({ field: { name, value, onChange } }) => (
              <Checkbox
                name={name}
                checked={value}
                disabled={!descripcion.isUserConfigurable}
                onChange={onChange} />
            )}
            control={control}
            defaultValue={descripcion?.isEnabled}
            name={'description'}
          />
        </div>

        <div style={{ width: '100%' }}>
          <Typography color='text.grey' variant='h2' className='pt-3 pb-3'>
            {t('Descripción')}
          </Typography>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ width: '50%', marginTop: '1rem', paddingRight: '1rem' }}>
              <Typography color='text.grey' variant='body2' className='mb-3'>
                Versión en Español
              </Typography>
              <Controller
                render={({ field: { name, value, onChange } }) => (
                  <TextField
                    sx={{ '& .MuiInputBase-root': { height: '250px' } }}
                    multiline
                    fullWidth
                    name={name}
                    value={value}
                    onChange={onChange}
                  />
                )}
                control={control}
                name={'descriptionEsp'}
                defaultValue={descripcion?.values.es}
              />
            </div>
            <div style={{ width: '50%', marginTop: '1rem', paddingRight: '1rem' }}>
              <Typography color='text.grey' variant='body2' className='mb-3'>
                Versión en Inglés
              </Typography>
              <Controller
                render={({ field: { name, value, onChange } }) => (
                  <TextField
                    sx={{ '& .MuiInputBase-root': { height: '250px' } }}
                    multiline
                    fullWidth
                    name={name}
                    value={value}
                    onChange={onChange}
                  />
                )}
                control={control}
                name={'descriptionEng'}
                defaultValue={descripcion.values.en}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <StyledEngineProvider injectFirst>
      {!attributes &&
        <div style={{ width: "100%", textAlign: "center", marginTop: "50px" }}>
          <img
            src={`${IMAGES_PATH}/origino_logo.png`}
            className='pt-4'
            style={{ width: '250px', height: 'auto' }}
            alt='logo origino'
          />
          <br />
          <br />

          {errorMessage ?
            <div class='text-center' height='200px'>
              <Container className='container-trazabilidad'>
                <Typography color='text.primary' style={{ marginBottom: '25px' }}>
                  {t('qrTemplate.Lo sentimos')} &#128542;
                </Typography>
                <Typography color='text.secondary' >
                  {errorMessage}
                </Typography>
              </Container>
              <Footer />
            </div >
            : <Spinner size="sm" type="grow" />
          }
        </div>
      }
      <StylesProvider generateClassName={generateClassName}>
        <ThemeProvider theme={qrTheme}>
          {!editMode && <TrazabilidadBackground />}

          {attributes &&
            <>
              <Container className='container-trazabilidad' component={'form'} noValidate
                ref={myForm} onSubmit={handleSubmit(onSubmit)}>
                {!editMode
                  ?
                  <div style={{ paddingTop: '0rem', paddingBottom: '2rem', display: 'flex', justifyContent: 'flex-end' }} align="right">
                    <Select
                      name="language"
                      id="language"
                      onChange={(e) => handleChangeLanguage(e)}
                      sx={{ height: '2rem' }}
                      value={selectedLang}>
                      {Object.keys(languages).map((lng) => (
                        <MenuItem
                          value={lng}
                          key={lng}
                          disabled={selectedLang === lng}>
                          {languages[lng].nativeName}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                  :
                  <div style={{ paddingTop: '3rem', paddingBottom: '2rem', display: 'flex', justifyContent: 'flex-end' }} >
                    <Button variant='outlined' onClick={() => history.goBack()}>Cancelar</Button>
                    <Button variant='outlined' style={{ marginLeft: '15px' }} type='submit'>Guardar Template</Button>
                  </div>
                }
                <Typography color='text.secondary' variant='h2'>
                  {t('qrTemplate.Bienvenido!')} 👋
                </Typography>
                <Typography color='text.primary' variant='h1' className='pt-1'>
                  {t('qrTemplate.Conocé todo sobre este producto')}
                </Typography>

                {editMode && renderDescriptionEdit(attributes.find(att => att.name === 'description'))}

                <Typography color='text.grey' variant='h2' style={{ paddingTop: '3rem' }} >
                  {t('qrTemplate.Información de trazabilidad')}
                </Typography>
                {attributes.map((att, index) => (
                  !att.hide &&
                  <div style={editMode ? { border: '1px dashed #aaa', padding: '1rem', display: 'flex', flexDirection: 'row' } : {}} key={index}>
                    {editMode &&
                      <div style={{ display: 'flex', paddingTop: '30px' }}>
                        <Controller
                          render={({ field: { name, value, onChange } }) => (
                            <Checkbox
                              name={name}
                              checked={value}
                              disabled={!att.isUserConfigurable}
                              onChange={onChange} />
                          )}
                          control={control}
                          name={att.name}
                          defaultValue={att.isEnabled}
                        />
                      </div>}
                    {renderField(att, index)}
                  </div>
                ))
                }
                {editMode &&
                  <div style={{ paddingTop: '1rem', display: 'flex', justifyContent: 'flex-end' }} >
                    <Button variant='outlined' onClick={() => history.goBack()}>Cancelar</Button>
                    <Button variant='outlined' style={{ marginLeft: '15px' }} type='submit'>Guardar Template</Button>
                  </div>
                }
              </Container>
              <Footer tt={t} />
            </>
          }

        </ThemeProvider>
      </StylesProvider>
    </StyledEngineProvider>
  );
};
