import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Typography, Button } from '@mui/material';
import { StyledCredentials } from "../SignIn.styles";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/pro-light-svg-icons';
import { faCopy } from '@fortawesome/pro-light-svg-icons';
import { faCircleCheck } from '@fortawesome/pro-light-svg-icons';
import BaseBasicForm from '../../../Layout/containers/BaseBasicForm';
import { ApiFetch } from '../../../Utils/ApiFetch';
import useGlobalContext from '../../../Hooks/useGlobalContext';
import { LOGIN } from "../../../constants/paths";
import leftIcon from '../../../Images/stepbystep/lefticons/nueva-cuenta.png'
import { useTranslation } from 'react-i18next';

function SignUpSandbox() {
  const { t } = useTranslation();
  let history = useHistory();
  const globalContext = useGlobalContext();
  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');
  const [credentialesCopy, setCredentialesCopy] = useState(false);


  useEffect(() => {
    registerUserSandbox();
  }, []);

  const handleOnClick = () => {
    history.push({
      pathname: LOGIN,
    });
  }

  // TODO: ENCRIPT PASSWORD
  // TODO: Generate a script or captcha to avoid massive uploads.
  const registerUserSandbox = () => {

    if (true) {

      ApiFetch(
        'users/signUp/sandbox',
        globalContext,
        false,
        async (result) => {
          if (result.status === 200) {
            let res = await result.json();
            if (res.user) {
              setUser(res.user.email);
              setPassword(res.user.userData.sandboxPass);
              localStorage.setItem("user_sandbox", res.user.email)
              localStorage.setItem("password_sandbox", res.user.userData.sandboxPass)
            }
            history.push({
              //pathname: '/public/signup/createUserSandbox',
            });
          }
        },
        (error) => {
          console.log('error users/signUp/sandbox', error);
        },
        {
          method: 'POST',
          body: JSON.stringify({}),
        }
      );
    }
  };



  return (
    <BaseBasicForm text={t("login.title.newUser")} leftIcon={leftIcon} showOriginoImage="true">
      <FontAwesomeIcon icon={faUserPlus} size="2x" color="#3969E8" />
      <Typography variant="title1" component="h4" color="text.primary" className="mb-4 mt-3">
        {t("signUpSandbox.title")}
      </Typography>

      <Typography variant="title1" component="h5" color="text.primary" style={{ fontWeight: 'normal', textAlign: 'center' }} className="mb-4 mt-3">
        {t("signUpSandbox.subtitle.0")}<br />{t("signUpSandbox.subtitle.1")}<br />
      </Typography>

      <StyledCredentials>
        <Typography variant="title1" component="h4" color="text.primary" style={{ fontWeight: 'normal', margin: "20px" }} className="mb-4 mt-3">
          Email: <b>{user}</b>  <FontAwesomeIcon onClick={
            () => {
              navigator.clipboard.writeText("Email: " + user + ", Password: " + password);
              setCredentialesCopy(true);
            }
          } style={{ fontSize: "20px", marginLeft: "10px", cursor: "pointer" }} icon={(credentialesCopy) ? faCircleCheck : faCopy} /><br />
          Password: <b>{password}</b><br />
        </Typography>
      </StyledCredentials>

      <Typography variant="title1" component="h5" color="text.primary" style={{ fontWeight: 'normal', textAlign: 'center' }} className="mb-4 mt-3">
        {t("signUpSandbox.msg1.0")}<br />{t("signUpSandbox.msg1.1")}
      </Typography>

      <Button variant="contained" className="mt-4" onClick={handleOnClick}>
        {t("signUpSandbox.access")}
      </Button>

    </BaseBasicForm>
  );
}

export default SignUpSandbox;
