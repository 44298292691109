import React from "react";
import { Switch } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { StyledEngineProvider } from "@mui/material/styles";
import { signInTheme } from "../pages/SignIn/signInTheme";
import PublicRoute from "../Components/Router/PublicRoute";
import GlobalContextProvider from "../Contexts/globalContext";
import { SignUpEmailVerify, SignInEmailVerified, SignUpUser, SignUpSandbox } from '../pages/SignIn'

function Main() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={signInTheme}>
        <Switch>
          <GlobalContextProvider>
            <PublicRoute path={"/public/signup/"} component={SignUpUser} exact />
            <PublicRoute path={"/register"} component={SignUpUser} exact />
            <PublicRoute path={"/public/signup/verify"} component={SignUpEmailVerify} exact />
            <PublicRoute path={"/public/signup/verify/hash/:hashParam"} component={SignInEmailVerified} exact />
            <PublicRoute path={"/public/signup/sandbox/create"} component={SignUpSandbox} exact />
          </GlobalContextProvider>
        </Switch>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default Main;
