import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Box, Divider } from '@mui/material';
import Typography from '@mui/material/Typography';
import useGlobalContext from '../../Hooks/useGlobalContext';
import useThemeContext from '../../Hooks/useThemeContext';
import { ApiFetch } from '../../Utils/ApiFetch';
import { HOME, IMAGES_PATH } from '../../constants/paths';
import BaseBasicForm from '../../Layout/containers/BaseBasicForm';
import leftIcon from '../../Images/stepbystep/lefticons/bienvenido.png';
import { useTranslation } from 'react-i18next';

const Login = () => {
  const { t } = useTranslation();
  const { swithMenuMode } = useThemeContext();
  let history = useHistory();
  const globalContext = useGlobalContext();
  const [user,setUser] = useState("");
  const [password,setPassword] = useState("");
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    bindCredentialsSandabox();
  }, []);

  const bindCredentialsSandabox = () => {
    let userSandbox = localStorage.getItem("user_sandbox");
    let passwordSandbox = localStorage.getItem("password_sandbox");
    if (userSandbox!=null && passwordSandbox!=null) {
      setUser(userSandbox);
      setPassword(passwordSandbox);
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);

    signIn();

    async function signIn() {
      const { token, refreshToken } = await getApiTokens();
      ApiFetch(
        'users/getInfo',
        globalContext,
        false,
        async (body) => {
          const user = await body.json();
          globalContext.login(token, user, refreshToken);
          swithMenuMode('HOME');
          history.push(HOME);
        },
        (error) => {
          // console.log(error);
        },
        {
          headers: new Headers({
            'Content-Type': 'application/json',
            Authorization: token,
          }),
        }
      );
    }

    function getApiTokens() {
      setShowError(false);
      return new Promise((resolve, reject) => {
        ApiFetch(
          'users/signIn',
          globalContext,
          false,
          (body) => {
            const token = async () => {
              const jsonResponse = await body.json();
              return {
                token: jsonResponse.token,
                refreshToken: jsonResponse.refreshToken,
              };
            };
            resolve(token());
          },
          (error) => {
            setShowError(true);
          },
          {
            method: 'POST',
            body: JSON.stringify({ email: data.get('email'), password: data.get('password') }),
          }
        );
      });
    }
  };

  return (
    <BaseBasicForm text={ t("login.title.welcome") } leftIcon={leftIcon} showOriginoImage="true">
        
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
      
      <Typography variant="title1" textAlign="center" component="h2" color="text.primary" style={{lineHeight:"2.5rem"}} className="mt-0">
        { t("signIn.noUserSandbox.0") }
        <b>{ t("signIn.noUserSandbox.1") }</b>
        { t("signIn.noUserSandbox.2") }  
        <img style={{width:"165px",marginLeft:"5px"}} src={`${IMAGES_PATH}/origino_logo.png`} alt="" />
      </Typography>
      <Box textAlign='center'>
        <Button 
        onClick={()=>{
          history.push("/public/signup/sandbox/create")
        }} 
        variant="contained" className="mt-4" size="large" type="button">
          { t("login.button.createAccount") }
        </Button>
      </Box>

      <Divider sx={{ borderBottomWidth: 3, mb:2, mt:15 }}/>
      <Typography variant="h5" component="h5" textAlign="center" color="text.primary" className="mb-0">
        { t("signIn.title") }
      </Typography>

        <TextField
          id="email"
          name="email"
          size="small"
          label="Email"
          className="mt-4"
          autoComplete="email"
          value={user}
          onChange={(val)=>{setUser(val.target.value)}}
          fullWidth
        />
        <TextField
          id="password"
          label="Contraseña"
          type="password"
          size="small"
          name="password"
          value={password}
          onChange={(val)=>{setPassword(val.target.value)}}
          helperText={ t("signIn.passwordHelperText") }
          className="mt-2"
          autoComplete="current-password"
          fullWidth
        />
        <br />
        <br />

        {/*<Typography variant="subtitle2" component="h4" color="text.secondary" className="mb-5">
          <Link href="#">Olvido su contraseña ?</Link>
        </Typography>*/}
        <Box textAlign='center'>
          <Button variant="outlined" className="mb-0" type="submit">
            { t("login.button.signIn") }
          </Button>
          {(showError) &&
          <span style={{textAlign:"center",color:"red"}}>
            <br/>{ t("signIn.errorMsg.loginError") }</span>
          }
        </Box>
        
      </Box>
    </BaseBasicForm>
  );
};
export default Login;
