import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Multiselect from 'multiselect-react-dropdown';
import React, { useState, useEffect } from 'react';
import FileField from '../../../Components/Generic/Fields/FileField';
import { useTranslation } from 'react-i18next';
import useGlobalContext from '../../../Hooks/useGlobalContext';
import BasicFieldCollection from '../../../Components/Generic/basicFieldCollection';
import LocationField from '../../../Components/Generic/Fields/LocationField';


const VALID_FORMATS = [
  '.jpg',
  '.png',
  '.jpeg',
  '.gif',
  '.pdf',
  '.docx',
  '.doc',
];

function DialogPop({
  dataType,
  value,
  valuesSelect,
  open,
  onChange,
  titleField,
  name,
  params,
  attributeLocal,
  temporalPk,
  globalModel,
}) {
  const { t } = useTranslation();
  const globalContext = useGlobalContext();
  const { selectedRowGridMassiveEdit, selectedAsset, selectedBox } =
  useGlobalContext();
  const [fieldsValues, setFieldsValues] = useState('');
  const [selectedValues, setSelectedValues] = useState([]);
  const [options,setOptions] = useState([]);

  const handleOnChange = ({ name, statusInfo }) => {
    setFieldsValues(statusInfo);
  };

  const handleClose = (saveData) => {
    onChange({ state: false, saveData, fieldValue: fieldsValues });
  };

  const onChangeDetail = (name, val) => {
    setFieldsValues(val);
  }

  const onChangeMultiselect = (val) => {
    let valuesTemp = { ...fieldsValues };

    let valTemp = [];
    for (let item of val) {
      valTemp.push(item.id);
    }
    valuesTemp = valTemp.join(',');
    setFieldsValues(valuesTemp);
  };

  const onChangeLocation = (name, val) => {
    setFieldsValues(val);
  };

  const handleOnSave = () => handleClose(true);
  const handleOnCancel = () => handleClose(false);
  const handleOnSelect = (val) => onChangeMultiselect(val);
  const handleOnRemove = (val) => onChangeMultiselect(val);
  const handleSelectedValues = () => {
    return value === '' || value === undefined || value === null
      ? []
      : value.split(',').map((item) => {
          let label = item;
          for (let row of valuesSelect) {
            if (row.key === item) {
              label = row.label;
            }
          }
          return { name: label, id: item };
        });
  };

  const hasSelectedValues = () => {
    return fieldsValues?.length < 1;
  }
  const handleOptions = (selectedRowGridMassiveEditArg) => {
    /*return valuesSelect.map((item) => {
      return { name: item.label, id: item.key };
    });*/

    let actualField = name;
    let models = globalContext.getSessionDataByKey('models');
    let model = selectedAsset.id;
    let attributes = models[model].attributes;
    let fkFlag=false;
    let fkField='';
    for (let attr of attributes) {
      if (attr.name===actualField) {
        if (attr.fkField) {
          fkFlag=true;
          fkField=attr.fkField;
        }
      }
    }

    let ret = [];
    if (valuesSelect==null) {
      return ret;
    }
    for (let item of valuesSelect) {
      if (fkFlag===false) {
        ret.push({ name: item.label, id: item.key });
      } else {
        if (selectedRowGridMassiveEditArg) {
          if (item.fk===selectedRowGridMassiveEditArg[fkField]) {
            ret.push({ name: item.label, id: item.key });
          }
        }
      }
    }
    return ret;
  };

  useEffect(() => {
    if (open) {
      setOptions(handleOptions(selectedRowGridMassiveEdit))
      if (dataType==='multiselect') {
        setSelectedValues(handleSelectedValues());
      }
    }
  }, [selectedRowGridMassiveEdit,open]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{titleField}</DialogTitle>
      <DialogContent style={{ minHeight: '150px', minWidth: '500px' }}>
        {dataType === 'multiselect' && (
          <Multiselect
            name="field"
            placeholder={t("stepByStep.button.cancel")}
            displayValue="name"
            options={options}
            selectedValues={selectedValues}
            onSelect={handleOnSelect}
            onRemove={handleOnRemove}
          />
        )}

        {dataType === 'file' && (
          <FileField
            name="file"
            onChange={handleOnChange}
            value={value}
            validFormats={VALID_FORMATS}
          />
        )}

        {dataType === 'location' && (
          <LocationField
            label={titleField}
            name={name}
            onChange={onChangeLocation}
            value={value}
            modeSatelite={attributeLocal.locationSatelite ? attributeLocal.locationSatelite : false}
          />
        )}

        {dataType === 'detail' && (
          <BasicFieldCollection
            mode={'update'}
            pkObjectType={attributeLocal.detailModel}
            objectType={'asset_detail'}
            parentType={'box'}
            parentTypeSubType={selectedBox.subtype}
            parentId={selectedBox.id}
            fields={attributeLocal.detailFields}
            gridColSize={attributeLocal.gridColSize}
            form={attributeLocal.detailForm}
            pluginsContext={{
              plugins: attributeLocal.plugins,
              fieldsValues: params.row,
              labels: attributeLocal.label,
            }}
            labels={attributeLocal.detailLabels}
            pk={params.id}
            model={selectedAsset.id}
            onChange={(val) => onChangeDetail(attributeLocal.name, val)}
            globalModel={globalModel}
            temporalPkReferer={temporalPk.id}
            field={attributeLocal}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOnCancel}>{t("stepByStep.button.cancel")}</Button>
        <Button disabled={hasSelectedValues()} onClick={handleOnSave}>{t("stepByStep.button.submit")}</Button>
      </DialogActions>
    </Dialog>
  );
}

export default DialogPop;
