import {
  Alert,
  Avatar,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useGlobalContext from '../../Hooks/useGlobalContext';
import { ApiFetch } from '../../Utils/ApiFetch';

import { useTranslation } from 'react-i18next';
import {
  BOXES_ALL,
  COMPANY_EDIT,
  COMPANY_NEW,
} from '../../constants/paths';
import PopoverOptions from './components/PopoverOptions';

function CompanyAll() {
  const { t } = useTranslation();
  let history = useHistory();
  const globalContext = useGlobalContext();
  const { setSelectedCompanyAdmin, calculateActualQuota, selectedCompanyAdmin } = globalContext;

  const [companies, setCompanies] = useState([]);
  const [alertInfo, setAlertInfo] = useState({
    show: false,
    alertText: '',
  });

  const loadQuotaModal = async () => {
    const { remainingQuota } = await calculateActualQuota({
      id: selectedCompanyAdmin.id,
      type: 'company'
    });
    if (remainingQuota <= 0) {
      setAlertInfo((current) => ({
        ...current,
        show: true,
        alertText: t('quotaExceeded', { type: t('company') }),
      }));
    }
  };

  useEffect(() => {
    getAllCompanies();
    loadQuotaModal();
  }, []);

  const getAllCompanies = () => {
    ApiFetch(
      'companies/all',
      globalContext,
      true,
      async (res) => {
        if (res.status === 200) {
          let data = await res.json();
          setCompanies(data);
        }
      },
      (error) => {
        console.log('error companies/all', error);
      },
      {
        method: 'GET',
      }
    );
  };

  const onClick = (company) => {
    setSelectedCompanyAdmin({ id: company.id, name: company.value });

    history.push({
      pathname: BOXES_ALL,
    });
  };

  const onClickAddCompany = () => {
    let args = {
      companyId: null,
    };

    history.push({
      pathname: COMPANY_NEW,
      state: args,
    });
  };

  const onClickEditCompany = (companyId) => {
    let args = {
      companyId: companyId,
    };

    history.push({
      pathname: COMPANY_EDIT,
      state: args,
    });
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} className="mb-2">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center">
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit">
              {t('stepByStep.company.title')}
            </Link>
          </Breadcrumbs>
          {alertInfo.show && (
            <span className="d-flex flex-row align-center">
              <Alert severity="error">
                <div
                  dangerouslySetInnerHTML={{
                    __html: alertInfo.alertText,
                  }}></div>
              </Alert>
            </span>
          )}
          <Button
            className="ml-2"
            variant="contained"
            disabled={alertInfo.show}
            onClick={() => {
              onClickAddCompany();
            }}>
            {t('stepByStep.button.company.new')}
          </Button>
        </Stack>
      </Grid>

      {companies.length === 0 && (
        <Grid item xs={4}>
          <div>{t('stepByStep.company.noData')}</div>
        </Grid>
      )}

      {companies.map((data) => (
        <Grid item key={data.id} >
          <Card sx={{ width: '400px', height: 150 }}>
            <CardHeader
              avatar={<Avatar aria-label="recipe">
                {data.value && data.value[0].toUpperCase()}
              </Avatar>}
              title={<Typography
                noWrap
                variant="h6"
                sx={{ cursor: 'pointer', maxWidth: '250px' }}
                color="primary"
                onClick={() => onClick(data)}
              >
                {data?.value}
              </Typography>
              }
              action={<PopoverOptions
                onClickEditCompany={() => {
                  onClickEditCompany(data.id);
                }}
                data={data}
              />}
            />
            <CardContent>
              <Typography variant="body2" color="text.secondary">
                {t('stepByStep.company.cardTitle')} {data?.origin}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}

export default CompanyAll;
