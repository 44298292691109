import { Divider, Typography } from '@mui/material';
import { PhotoCarrousel } from './PhotoCarrousel';

export const AttributeImages = ({ data, tt }) => {
  return (
    <>
      <Divider />
      <Typography color='text.secondary' variant='h3' >
        {tt(`qrTemplate.${data.label}`)}
      </Typography>
      <PhotoCarrousel photos={data.value} />
    </>
  );
};
