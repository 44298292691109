import { ImageListItem } from '@mui/material';
import Carousel from 'react-material-ui-carousel'

export const PhotoCarrousel = ({ photos }) => {

  if (!Array.isArray(photos)) {
    photos = [photos]
  }

  return (
    <Carousel swipe>
      {photos.map((photo, index) => (
        <ImageListItem
          key={index}
          sx={{height:'360px',overflow:'hidden'}}
        >
          <img
            src={photo}
            style={{height:'360px',objectFit:'contain'}}
            alt={'ImageList img'}
            loading="lazy"
          />
        </ImageListItem>
      ))}
    </Carousel>
  );
};
