import React, { useEffect } from 'react';
import {
  StyledBox,
  StyledListItemText,
  StyledMenuItem,
  StyledMenuList,
  StyledDivider,
} from './SidebarPopover.styles';
import { Box, Stack, Button, Grid } from '@mui/material';
import { ApiFetch } from '../../../Utils/ApiFetch';
import useGlobalContext from '../../../Hooks/useGlobalContext';
import { useTranslation } from 'react-i18next';


function SidebarLevel(props) {
  const { t } = useTranslation()
  const [selectedIndex, setSelectedIndex] = React.useState(-1);  
  const [selectedItem, setSelectedItem] = React.useState({});  
  const [boxes, setBoxes] = React.useState([]);
  const [show, setShow] = React.useState(false);
  const [levelRoot,setLevelRoot] = React.useState([]);
  const [labelBox, setLabelBox] = React.useState('');
  const globalContext = useGlobalContext();
  const [clicked,setClicked] = React.useState(false);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };
  
  // /boxes/:id/:suplychainId/:companyId/parent
  useEffect(() => {
    ApiFetch(
      'topBar/boxes/'+props.level.parentId+'/'+props.level.selectedChain.id+'/'+props.level.selectedCompany.id+'/parent',
      globalContext,
      true,
      async (res) => {
        if (res.status === 200) {
          //setChains(await res.json());          
          setBoxes(await res.json());
        }
      },
      (error) => {
        console.log('error topBar/boxes', error);
      },
      {
        method: 'GET',
      }
    );
  }, [props.level.parentId]);

  useEffect(() => {
    if (props.level.parentId===0) {
      setLabelBox( t('menu.box') );
      setLevelRoot(props.level.selectedCompany.name);
    } else {
      setLabelBox( t('menu.subBox') );
      setLevelRoot(props.level.parentName);
    }    
  }, [props.level.parentId, props.level.selectedCompany]);

  useEffect(() => {
    if (boxes.length>0) {
      setShow(true);
    }
  }, [boxes]);

  
  return (
  <>
  {(show) && 
  <Grid item>        
  <StyledBox>
    <Stack direction="row" justifyContent="stretch" alignItems="stretch">
      
        <StyledMenuList dense>
          <StyledMenuItem className="unselectable">
            <StyledListItemText>
              <strong>{levelRoot}</strong>
            </StyledListItemText>
          </StyledMenuItem>
          <StyledDivider />
          <StyledMenuItem className="unselectable">
            <StyledListItemText><small>{labelBox}</small></StyledListItemText>
          </StyledMenuItem>
          <StyledDivider />

          {boxes.map((item, index) => (
            <StyledMenuItem
              key={index}
              selected={
                (selectedIndex === index)
                ?
                true
                :
                (props.selectedItem.nav)
                ?
                (props.selectedItem.nav[props.index])
                ?
                (item.id === props.selectedItem.nav[props.index].id && !clicked)
                ?
                true
                :
                false
                :
                false
                :
                false
              }
              // || item.id === props.selectedItem.id
              onClick={(event) => {
                setClicked(true);
                handleListItemClick(event, index);
                props.onSelect(props.index,{id:item.id,name:item.value,subtype:item.subtype});
                setSelectedItem({id:item.id,name:item.value,subtype:item.subtype});                
              }}              
              >
              <StyledListItemText>{item.value}</StyledListItemText>
            </StyledMenuItem>
          ))}

          {selectedIndex !== -1 && (
            <Box className="d-flex justify-center align-center">
              <div style={{marginBottom:"20px"}}>
              <Button onClick={()=>{props.onAcceptItem(selectedItem)}} className="mt-3" variant="contained">
                SELECCIONAR
              </Button>              
              </div>
            </Box>
          )}
        </StyledMenuList>
      
    </Stack>
  </StyledBox>
  </Grid>
  }
  </>
  )
}

export default SidebarLevel;
