import { Divider, Typography, Link } from '@mui/material';
import React from 'react';
import { IMAGES_PATH } from '../../../constants/paths';
export const Footer = (props) => {

  const openLink = (url) => {
    var a = document.createElement('a');
    a.href = url;
    a.target = '_blank';
    document.body.appendChild(a);
    a.click();
  }

  return (
    <>
      <Divider sx={{ width: '100%' }} />
      {props.tt &&
        <Typography
          component='div'
          variant='h6'
          color='text.grey'
          className='d-flex justify-center pt-3 text-center'
        >
          <p>
            {props.tt('Conozca más sobre la tokenización y trazabilidad blockchain de activos reales de Origino ')}
            <Link style={{ cursor: 'pointer !important' }} onClick={() => openLink('https://origino.io')}> {
              props.tt('aquí')
            }</Link>
          </p>
        </Typography>
      }
      <div className='d-flex justify-center pb-3'>
        <img
          src={`${IMAGES_PATH}/origino_logo.png`}
          className='pt-4'
          style={{ width: '250px', height: 'auto' }}
          alt='logo originio'
        />
      </div>
    </>
  );
};
