import { Divider, Typography } from '@mui/material';
import { useState } from 'react';

export const AttributeLink = ({ data, tt }) => {
  const [links] = useState(data.value.split(','))

  return (
    <>
      <Divider />
      <Typography color='text.secondary' variant='h3'>
        {tt(`qrTemplate.${data.label}`)}
      </Typography>
      {links.map((link) => <Typography color='text.grey' variant='subtitle1'>
        <a target='_blank' rel='noreferrer' href={link}>{link.split('//')[1]}</a>
      </Typography>)}
    </>
  );
};
