import React from 'react';
import { Grid, Stack, Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faDownload } from '@fortawesome/pro-light-svg-icons';
import { LotsTable } from './components';
import useGlobalContext from '../../Hooks/useGlobalContext';
import { useHistory } from 'react-router-dom';
import {
  LOTS_NEW,
} from '../../constants/paths';
import { useTranslation } from 'react-i18next'

function LotsMain() {
  const { t } = useTranslation()
  const history = useHistory();
  const { userData, selectedBox, selectedAsset } =
    useGlobalContext();

  const newLotsAction = () => {
    let args = {
      pk: null,
      mode: 'new',
      objectTypeSubType: selectedAsset.id,
      globalModel: selectedAsset.id,
      parentType: 'box',
      parentTypeSubType: selectedBox.subtype,
      parentId: selectedBox.id,
    };

    history.push({
      pathname: LOTS_NEW,
      state: { refererContext: args },
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <div>
            {(process.env.REACT_APP_HIDE_NOT_IMPLEMENTED === 'true' ? false : true) && (
              <Button
                sx={{ ml: 2 }}
                variant="outlined"
                startIcon={<FontAwesomeIcon icon={faDownload} />}>
                {t("tags.button.download")}
              </Button>
            )}
            <Button
              sx={{ ml: 2 }}
              variant="contained"
              disabled={!(userData?.userData?.permissions?.boxes[selectedBox.id + "-" + selectedAsset.id]?.includes("TAG_ADD") === true)}
              onClick={() => {
                newLotsAction();
              }}
              startIcon={<FontAwesomeIcon icon={faPlus} />}>
              {t("stepByStep.button.newTag")}
            </Button>
          </div>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <LotsTable />
      </Grid>
    </Grid>
  );
}

export default LotsMain;
